export default function ({ store, app }) {
  const siteId = store.state.site_id;

  // Определяем путь к фавиконкам в зависимости от siteId
  const faviconPath = siteId === 483 ? '/favicon-gagarina' : '';

  // Устанавливаем иконки в зависимости от пути
  app.head.link = [
    { rel: 'icon', href: '/favicon.ico', sizes: '512x512' },
    { rel: 'icon', href: '/favicon.svg', type: 'image/svg+xml' },
    { rel: 'mask-icon', href: '/mask-icon.svg', color: '#00abf0' },
    { rel: 'manifest', href: '/manifest.json' },
    {
      rel: 'apple-touch-icon',
      href: 'https://g-enzes.ru/apple-touch-icon.png',
      type: 'image/png',
      sizes: '180x180'
    },
    { rel: 'apple-touch-icon', href: '/apple-touch-icon-512x512.png', type: 'image/png', sizes: '512x512' },
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon-120x120-precomposed.png',
      sizes: '120x120'
    },
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon-120x120.png',
      type: 'image/png',
      sizes: '120x120'
    }
  ]
}
